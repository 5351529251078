import React, {Component} from 'react'
import {ArticulosService, ClientesService, CotizacionesService, MastersService, PedidosService, UtilsService, VendedoresService} from "services";
import {MainContext} from "contexts/main.context";
import {Box, Fab, Tooltip} from "@material-ui/core/index";
import {DireccionEntregaDialog} from './dialogs/direccionEntrega.dialog';
import {ConfirmationDialog} from './dialogs/confirm.dialog';
import {PedidosOperation} from 'components/ventas/pedidos/operation/pedidos.operation';
import MomentUtils from "@date-io/moment/build/index";
import 'moment/locale/es';
import {armadoPartSuffixes, cloneArray, regExpPercentage} from "assets/utils.js";
import {withSnackbar} from "notistack";
import DoneIcon from '@material-ui/icons/Done';
import {calcImporteByArticulo} from "assets/utils";

export class PedidosCreate extends Component {
    state = {
        cliente: {},
        deudaPendiente: false,
        documentoPendiente: false,
        direccionesEntrega: [],
        condComerciales: [],
        vendedores: [],
        talfacturas: [],
        talremitos: [],
        tipoasientos: [],
        jurisdicciones: [],
        transportes: [],
        articulos: [],
        articulosArmados: [],
        bonificacionesLitros: [],
        headerData: {
            bonificacion: 0,
            listaprecio: '1',
            condcomercial: '',
            notacompra: '',
            usuario: this.context.loggedUser.name,
            vendedor: this.context.loggedUser.id,
            ingreso: (new MomentUtils()).date(new Date()),
            entrega: (new MomentUtils()).date(new Date()),
            leyenda1: '',
            leyenda2: '',
            leyenda3: '',
            leyenda4: '',
            tipoasiento: '1',
            talfactura: '50',
            talremito: '4',
            jurisdiccion: '',
            transporte: '000',
            direccionEntrega: {},
            isPrepago: false
        },
        selectedArticulos: [],
        generatedId: '',
        totalArticulos: 0,
        totalLitros: 0,
        importe: 0,
        maxArticulos: 0,
        direccionEntregaDialogOpen: false,
        confirmationDialogOpen: false,
        successDialogOpen: false,
        debounce: false
    };

    PedidosService = new PedidosService(this.context);
    CotizacionesService = new CotizacionesService(this.context);
    MastersService = new MastersService(this.context);
    UtilsService = new UtilsService(this.context);
    VendedoresService = new VendedoresService(this.context);
    ClientesService = new ClientesService(this.context);
    ArticulosService = new ArticulosService(this.context);

    componentDidMount() {
        this.ArticulosService.getAll(
            this.props.match.params.cliente,
            response => {
                // FIX 21/03/23 - Quito los que son PROMO (no es posible seleccionarlos sino a través de productos armados)
                let articulos = response.data.result.filter(articulo => armadoPartSuffixes.every(suffix => !articulo?.id.endsWith(suffix)));
                articulos = articulos.map(articulo => ({...articulo}));
                this.setState({articulos});
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.ArticulosService.getArmados(
            false,
            this.props.match.params.cliente,
            response => {
                let articulosArmados = response.data.result;
                articulosArmados = articulosArmados.map(articulo => ({...articulo, armado: true}));
                this.setState({articulosArmados});
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.ClientesService.getById(
            this.props.match.params.cliente,
            response => {
                this.setState(prevState => {
                    prevState.cliente = response.data.result;
                    prevState.headerData.bonificacion = response.data.result.bonificacion;
                    prevState.headerData.listaprecio = response.data.result.listaprecio;
                    prevState.headerData.condcomercial = response.data.result.condcomercial || '1';
                    prevState.headerData.vendedor = response.data.result.vendedor;
                    prevState.headerData.jurisdiccion = response.data.result.jurisdiccion;
                    prevState.headerData.transporte = response.data.result.transporte || '000';
                    return prevState;
                })
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.ClientesService.getDeudaPendiente(
            this.props.match.params.cliente,
            response => this.setState({deudaPendiente: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.UtilsService.getDocumentosImpagos(
            this.props.match.params.cliente,
            response => {
                const documentos = response.data.result;
                const documentoPendiente = documentos.some(documento => (documento.numcompdocu.substr(3, 2) === "17" && !["AP", "CRE"].includes(documento.tipocompdocu)));
                this.setState({documentoPendiente: documentoPendiente})
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.ClientesService.getDireccionesEntrega(
            this.props.match.params.cliente,
            response => this.setState({direccionesEntrega: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        if (this.props.match.params.cotizacion)
            this.loadCotizacionReferencia(this.props.match.params.cotizacion);

        this.PedidosService.getMaxLength(
            response => this.setState({maxArticulos: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.MastersService.getCondicionesVenta(
            response => this.setState({condComerciales: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.MastersService.getTiposDeAsiento(
            response => this.setState({tipoasientos: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.MastersService.getTalonariosFactura(
            response => this.setState({talfacturas: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.MastersService.getTalonariosRemito(
            response => this.setState({talremitos: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.MastersService.getJurisdicciones(
            response => this.setState({jurisdicciones: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.MastersService.getBonificacionesByRangoLitros(
            response => this.setState({bonificacionesLitros: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    loadCotizacionReferencia = (idCotizacion) => {
        this.CotizacionesService.getById(
            idCotizacion,
            response => {
                const cotizacion = response.data.result;
                this.setState(prevState => {
                    prevState.headerData = {
                        ...prevState.headerData,
                        ...cotizacion,
                        // Fix campos (no deben copiarse de la cotización de referencia)
                        usuario: this.context.loggedUser.name,
                        ingreso: (new MomentUtils()).date(new Date()),
                        entrega: (new MomentUtils()).date(new Date()),
                        leyenda3: cotizacion.isprepago ? cotizacion.leyenda3 : ""
                    };
                    prevState.importe = parseFloat(cotizacion.impsubtotal);
                    return prevState;
                });

                if (cotizacion.vencida === true)
                    this.props.enqueueSnackbar("La cotización de referencia está vencida, no es posible ingresar el pedido.", {variant: 'error'});
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.CotizacionesService.getArticulosById(
            idCotizacion,
            response => {
                let articulos = response.data.result;
                articulos.forEach(articulo => {
                    articulo.importe = calcImporteByArticulo(articulo.cantidad, articulo.precio, articulo.bonificacion);
                    articulo.deposito = 2;
                    articulo.adescargar = articulo.cantidad;
                    articulo.afacturar = articulo.cantidad;
                });
                this.setState({
                    selectedArticulos: articulos,
                    totalArticulos: articulos.reduce((count, articulo) => (count + parseInt(articulo.cantidad)), 0)
                });
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    };

    onCreate = () => {
        if (this.validateData())
            this.onOpenConfirmationDialog();
    };

    validateData = () => {
        // Valido que la cotización de referencia (si existe) no esté vencida.
        if (this.state.headerData.vencida === true) {
            this.props.enqueueSnackbar("La cotización de referencia está vencida, no es posible ingresar el pedido.", {variant: 'error'});
            return false;
        }

        // Valido que el cliente tenga direcciones de entrega disponibles. Si no tiene no puede ingresar el pedido.
        // FIX 15/07 - La validacíón no aplica para la empresa coutoune.
        if (this.state.direccionesEntrega.length < 1 && this.context.loggedUser.cmpid !== 'coutoune') {
            this.props.enqueueSnackbar('NO es posible ingresar pedidos. El cliente no tiene direcciones de entrega disponibles.');
            return false;
        }

        // Valido que el cliente no tenga deuda y si tiene chequeo el rol del usuario, la categoría del vendedor (si el usuario es vendedor) y el id del proveedor.
        if (this.state.deudaPendiente) {
            if (this.context.loggedUser.role === 'A' || this.context.loggedUser.role === 'S' || this.context.loggedUser.role === 'O')
                this.props.enqueueSnackbar('ATENCIÓN: Este cliente presenta documentos impagos con fechas anteriores al límite impuesto.', {variant: 'warning'});
            else if (this.context.loggedUser.categoria === '123' && this.state.cliente.proveedor !== '123') {
                this.props.enqueueSnackbar('NO es posible ingresar pedidos. Este cliente presenta documentos impagos con fechas anteriores al límite impuesto.', {variant: 'error'});
                return false;
            } else
                this.props.enqueueSnackbar('ATENCIÓN: Este cliente presenta documentos impagos con fechas anteriores al límite impuesto. Puede ingresar pedidos pero no entregarlos ni facturarlos.', {variant: 'warning'});
        }

        // Valido que el cliente no tenga documentos a cancelar del punto de venta 17.
        if (this.state.documentoPendiente) {
            if (this.context.loggedUser.role === 'A' || this.context.loggedUser.role === 'S' || this.context.loggedUser.role === 'O')
                this.props.enqueueSnackbar('ATENCIÓN: Este cliente presenta documentos impagos del punto de venta 17.', {variant: 'warning'});
            else {
                this.props.enqueueSnackbar('NO es posible ingresar pedidos. Este cliente presenta documentos impagos del punto de venta 17.', {variant: 'error'});
                return false;
            }
        }

        // Valido que tenga una dirección de entrega seleccionada.
        // FIX 15/07 - La validación no se realiza para coutoune.
        if (this.context.loggedUser.cmpid !== 'coutoune') {
            let data = {...this.state.headerData};
            if (Object.keys(data.direccionEntrega).length === 0) {
                this.props.enqueueSnackbar('Debe seleccionar una dirección de entrega para el pedido', {variant: 'error'});
                return false;
            }
        }

        return this._validateArticulos() !== false;
    };

    onConfirm = () => {
        if (this.state.debounce) return;

        this.setState({debounce: true}, () => {
            const cliente = this.state.cliente.id;
            let data = {...this.state.headerData};

            data.ingreso = data.ingreso.format('DD/MM/Y');
            data.entrega = data.entrega.format('DD/MM/Y');
            data.importe = this.state.importe;
            data.articulos = this.state.selectedArticulos;

            // Seteo la cotización de referencia (si se utilizó para generar el pedido)
            data.cotizacion = '';
            if (this.props.match.params.cotizacion)
                data.cotizacion = this.props.match.params.cotizacion;

            this.PedidosService.create(
                cliente,
                data,
                response => {
                    this.setState({generatedId: response.data.id}, () => {
                        this.props.enqueueSnackbar(`Se generó correctamente el pedido número ${response.data.id}`, {variant: 'success'});
                        this.props.history.push(`/ventas/pedidos/${this.props.match.params.cliente}`);
                    });
                },
                error => this.props.enqueueSnackbar(error, {variant: 'error'})
            );

            setTimeout(() => this.setState({debounce: false}), 500);
        });
    };

    onChangeHeaderData = (field, value) => {
        if (field === 'bonificacion')
            value = regExpPercentage.test(value) ? value : this.state.headerData.bonificacion;

        this.setState(prevState => {
            prevState.headerData[field] = value;
            return prevState;
        });
    };

    plainArticulos = (articulos) => {
        return articulos.map(articulo => {
            if (articulo.armado) {
                const armadoGroupId = Math.floor(Math.random() * 90000 + 10000);
                return articulo.articulos.map(_articulo => {
                    const cantidad = _articulo.cantidad * articulo.cantidad;
                    const precio = _articulo[`lista${this.state.headerData.listaprecio}`];
                    const bonificacion = (!isNaN(parseInt(_articulo.bonificacion_cliente))) ? _articulo.bonificacion_cliente : (_articulo.bonificacion) ? _articulo.bonificacion : 0;
                    return ({
                        ..._articulo,
                        cantidad: cantidad,
                        adescargar: cantidad,
                        afacturar: cantidad,
                        precio: precio,
                        bonificacion: bonificacion,
                        importe: calcImporteByArticulo(cantidad, precio, bonificacion),
                        fromArmado: armadoGroupId
                    })
                });
            } else
                return articulo;
        }).flat();
    }

    onAddArticulos = (articulos, fnSuccess) => {
        // Aplano los artículos armados para validar.
        articulos = this.plainArticulos(articulos);

        // Valido que no quiera ingresar más artículos que los permitidos.
        if ((this.state.selectedArticulos.length + articulos.length) > this.state.maxArticulos) {
            this.props.enqueueSnackbar(`No puede ingresar más de ${this.state.maxArticulos} artículos en un pedido.`, {variant: 'error'});
            return;
        }

        // Valido que los artículos no estén mezclados entre shell y otros proveedores.
        // let aIsShell = new Set();
        // if (this.state.selectedArticulos.length > 0) aIsShell.add(this.state.selectedArticulos[0].is_shell);
        // articulos.forEach(articulo => aIsShell.add(articulo.is_shell));
        //
        // if (aIsShell.size > 1) {
        //     this.props.enqueueSnackbar(`No puede ingresar artículos de Shell y de otros proveedores en un mismo pedido`);
        //     return;
        // }

        // Valido que los artículos no tengan distintas alícuotas de IVA.
        let aIVA = new Set();
        if (this.state.selectedArticulos.length > 0) aIVA.add(this.state.selectedArticulos[0].ivavta);
        articulos.forEach(articulo => aIVA.add(articulo.ivavta));

        if (aIVA.size > 1) {
            this.props.enqueueSnackbar(`No puede ingresar artículos con distintas alícuotas de IVA en un mismo pedido`, {variant: 'error'});
            return;
        }

        if (!articulos.every(articulo => this._validateArticulo(articulo) === true))
            return;

        this._updateArticulos(this.state.selectedArticulos.concat(articulos), fnSuccess);
    };

    onEditArticulo = (articulo, fnSuccess) => {
        if (this._validateArticulo(articulo)) {
            const articulos = this.state.selectedArticulos.map(_articulo => _articulo.key === articulo.key ? articulo : _articulo);
            this._updateArticulos(articulos, fnSuccess);
        }
    };

    onRemoveArticulos = articulosToRemoveIds => {
        const articulosToRemove = this.state.selectedArticulos.filter(selectedArticulo => articulosToRemoveIds.includes(selectedArticulo.key));
        let articulosToKeep = this.state.selectedArticulos.filter(selectedArticulo => {
            for (let articuloToRemove of articulosToRemove)
                if (selectedArticulo.key === articuloToRemove.key || (!!selectedArticulo.fromArmado && selectedArticulo.fromArmado === articuloToRemove.fromArmado))
                    return false;
            return true;
        });
        this._updateArticulos(articulosToKeep);
    };

    onOpenDireccionEntregaDialog = () => this.setState({direccionEntregaDialogOpen: true});
    onCloseDireccionEntregaDialog = () => this.setState({direccionEntregaDialogOpen: false});
    onConfirmDireccionEntregaDialog = (direccion) => {
        this.setState(prevState => {
            // Corto las leyendas a 50 caracteres y paso el excedente a la siguiente línea.
            let leye1 = `${direccion.id.padStart(5, '0')}-${direccion.direccion}`;
            prevState.headerData.leyenda1 = leye1.substr(0, 50);

            let excededLeye1 = leye1.substr(50, 50).trim();
            if (excededLeye1 !== "")
                excededLeye1 += '-';
            let leye2 = `${excededLeye1}${direccion.observacion}`;
            prevState.headerData.leyenda2 = leye2.substr(0, 50);

            let leye3 = leye2.substr(50, 50).trim();
            if (leye3 !== "")
                prevState.headerData.leyenda3 = leye3;

            prevState.headerData.jurisdiccion = direccion.jurisdiccion;
            prevState.headerData.direccionEntrega = direccion;
        });
    };

    onOpenConfirmationDialog = () => this.setState({confirmationDialogOpen: true});
    onCloseConfirmationDialog = () => this.setState({confirmationDialogOpen: false});

    _validateArticulos = () => {
        const articulos = this.state.selectedArticulos;
        for (var i in articulos) {
            const articulo = articulos[i];
            if (this._validateArticulo(articulo) === false)
                return false;
        }
    };

    enoughStockForArticulo = (articulo) => {
        let requiredQuantity = this.state.selectedArticulos.reduce(
            (sum, _articulo) =>
                sum + (_articulo.id === articulo.id && _articulo.key !== articulo.key ? parseInt(_articulo.cantidad) : 0), 0
        );
        requiredQuantity += parseInt(articulo.cantidad);
        let available = parseInt(articulo.disponible);
        return requiredQuantity <= available;
    };

    _validateArticulo = articulo => {
        // Calculo el descuento máximo permitido por escala de litros para los artículos de Shell.
        let bonificacionMaxima = 0;
        const totalLitros = this.state.totalLitros + (articulo.cantidad * articulo.unidad);
        this.state.bonificacionesLitros.forEach(bonificacion => {
            if (totalLitros > bonificacion.rango.inicio)
                bonificacionMaxima = bonificacion.bonificacion;
        });

        // Si el usuario no es Administrador sólo puede ingresar bonificaciones hasta 10%.
        // Fix 19/11/24 - Se quita esta restricción
        // if (articulo.bonificacion > 10 && isNaN(parseFloat(articulo.bonificacion_cliente)) && this.context.loggedUser.role !== 'A') {
        //     this.props.enqueueSnackbar(`La bonificación del artículo ${articulo.id} no puede ser mayor a 10%.`, {variant: 'error'});
        //     return false;
        // }

        if (!this.enoughStockForArticulo(articulo)) {
            if (articulo.notas.indexOf('8888') === -1) {
                this.props.enqueueSnackbar(`El artículo  ${articulo.id} no tiene suficiente stock para la cantidad ingresada. Si ingresa el pedido quedará con disponibilidad negativa.`, {variant: 'warning'});
            } else {
                // Artículo en promoción.
                this.props.enqueueSnackbar(`STOCK DE PROMOCIÓN AGOTADO: el artículo  ${articulo.id} no tiene stock de promoción disponible para realizar el pedido.`, {variant: 'error'});
                return false;
            }
        }

        // Valido que los valores del artículo no afecten la renta mínima:
        // articulo.importe contempla la suma bonificada * la cantidad pedida. El cálculo de renta mínima debe realizarse sobre el valor unitario
        // del artículo bonificado por unidad y por bonificacion general del cliente menos el costo neto.
        // Este valor final debe ser mayor al porcentaje de renta minima definido por articulo.rentaminima.
        const importeArticulo = articulo.importe / articulo.cantidad;
        const importeBonificado = importeArticulo - (importeArticulo * this.state.headerData.bonificacion) / 100;
        const rentabilidad = ((importeBonificado - articulo.costoneto) / articulo.costoneto) * 100;
        if (rentabilidad < articulo.rentaminima) {
            if (this.context.loggedUser.role !== 'A') {
                this.props.enqueueSnackbar(`El artículo ${articulo.id} no cumple con la rentabilidad mínima esperada. Revise su precio y bonificación unitarios o la bonificación general del cliente.`, {variant: 'error'});
                return false;
            } else
                this.props.enqueueSnackbar(`El artículo ${articulo.id} no cumple con la rentabilidad mínima esperada. Revise su precio y bonificación unitarios o la bonificación general del cliente.`, {variant: 'warning'});
        }

        return true;
    };

    _updateArticulos = (articulos, fnSuccess = () => {
    }) => this.setState(prevState => {
        prevState.selectedArticulos = cloneArray(articulos);
        prevState.totalArticulos = articulos.reduce((count, articulo) => (count + parseInt(articulo.cantidad)), 0);
        prevState.totalLitros = articulos.reduce((count, articulo) => (count + (parseInt(articulo.cantidad) * parseFloat(articulo.unidad))), 0);
        prevState.importe = this._calculateImporte(articulos);
        return prevState;
    }, fnSuccess);

    _calculateImporte = (articulos) => {
        return articulos.reduce((sum, articulo) => (sum + (isNaN(parseFloat(articulo.importe)) ? 0 : parseFloat(articulo.importe))), 0);
    };

    render() {
        const {
            cliente, condComerciales, vendedores, talfacturas, talremitos, tipoasientos, jurisdicciones, transportes, articulos, articulosArmados, bonificacionesLitros,
            headerData, selectedArticulos, generatedId, importe, maxArticulos, confirmationDialogOpen, totalArticulos, totalLitros,
            direccionEntregaDialogOpen, direccionesEntrega
        } = this.state;

        const importeBonificado = importe - importe * headerData.bonificacion / 100;

        return (
            <Box className='niquel-pedidos-create'>
                <PedidosOperation
                    operation='CREATE'
                    cliente={cliente}
                    condComerciales={condComerciales}
                    vendedores={vendedores}
                    talfacturas={talfacturas}
                    talremitos={talremitos}
                    tipoasientos={tipoasientos}
                    jurisdicciones={jurisdicciones}
                    transportes={transportes}
                    articulos={articulos.concat(articulosArmados)}
                    bonificacionesLitros={bonificacionesLitros}
                    headerData={headerData}
                    selectedArticulos={selectedArticulos}
                    generatedId={generatedId}
                    importe={importe}
                    totalArticulos={totalArticulos}
                    totalLitros={totalLitros}
                    maxArticulos={maxArticulos}
                    importeBonificado={importeBonificado}
                    onChangeHeaderData={this.onChangeHeaderData}
                    onAddArticulos={this.onAddArticulos}
                    onEditArticulo={this.onEditArticulo}
                    onRemoveArticulos={this.onRemoveArticulos}
                    onOpenDireccionEntregaDialog={this.onOpenDireccionEntregaDialog}
                />

                <Tooltip title={selectedArticulos.length < 1 ? '' : 'Generar Pedido'}>
                    <Box className='niquel-fab-btn'>
                        <Fab color="primary" aria-label="Add" disabled={selectedArticulos.length < 1} onClick={this.onCreate}>
                            <DoneIcon/>
                        </Fab>
                    </Box>
                </Tooltip>

                <DireccionEntregaDialog
                    open={direccionEntregaDialogOpen}
                    direccionesEntrega={direccionesEntrega}
                    selected={headerData.direccionEntrega}
                    editable={true}
                    onConfirm={(direccion) => {
                        this.onConfirmDireccionEntregaDialog(direccion);
                        this.onCloseDireccionEntregaDialog();
                    }}
                    onCancel={this.onCloseDireccionEntregaDialog}
                />

                <ConfirmationDialog
                    open={confirmationDialogOpen}
                    importe={importeBonificado}
                    cliente={cliente}
                    direccionEntrega={headerData.direccionEntrega}
                    onConfirm={() => {
                        this.onCloseConfirmationDialog();
                        this.onConfirm();
                    }}
                    onCancel={this.onCloseConfirmationDialog}
                    onOpenDireccionEntregaDialog={this.onOpenDireccionEntregaDialog}
                />
            </Box>
        )
    }
}

PedidosCreate.contextType = MainContext;
PedidosCreate = withSnackbar(PedidosCreate);
