import React from "react";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import {Button, IconButton, InputAdornment, TextField, Typography} from "@material-ui/core";
import {
    AddCircleOutlineRounded as FillIMaxIcon,
    AdjustRounded as AdjustIcon,
    DeleteRounded as DeleteIcon,
    DeleteRounded as RemoveIcon,
    InfoRounded as InfoIcon,
    MoreVertRounded as MenuIcon,
    RemoveCircleOutlineRounded as FillMinIcon,
    WarningRounded as WarningIcon,
} from "@material-ui/icons";
import {formatPrice, InputPrice, isPartialPayment, partialPayment} from "assets/utils";
import {MainContext} from "contexts/main.context";
import Tooltip from "@material-ui/core/Tooltip";
import {withSnackbar} from "notistack";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state'

const PrepagoIndicator = () => <Tooltip title={"Prepago"}><Typography component={"span"} color={"primary"} variant={"body2"}><b>P</b></Typography></Tooltip>;

export class CobranzaDocumentosList extends React.Component {
    render() {
        const {documentos, cobranza, operation, dateValidationError, notaCreditoValidationError} = this.props;
        const editable = operation === 'CREATE';

        return (
            <React.Fragment>
                <Grid container justifyContent={"space-between"}>
                    <Typography
                        variant={"caption"}
                        color={documentos.length <= 0 ? "error" : "initial"}
                    >
                        Documentos a Cancelar ({documentos.length})
                    </Typography>
                    <Typography
                        variant={"caption"}
                        color={documentos.length <= 0 ? "error" : "initial"}
                    >
                        Promedio días: {cobranza.documentos.promedio_ponderado}
                    </Typography>
                    <Box>
                        <Tooltip title={'Agregar Documento'}>
                            <span>
                                <Button
                                    color={"primary"}
                                    variant={"outlined"}
                                    size={"small"}
                                    onClick={this.props.onAdd}
                                    disabled={!editable}
                                >
                                    Agregar
                                </Button>
                            </span>
                        </Tooltip>
                        <Tooltip title={'Quitar todos'}>
                            <span>
                                <IconButton
                                    onClick={this.props.onRemoveAll}
                                    disabled={!editable}
                                    className={'ml-1'}
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.54) !important'
                                    }}
                                    size={"small"}
                                >
                                    <RemoveIcon/>
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={'Ajustar al saldo'}>
                            <span>
                                <IconButton
                                    onClick={this.props.onAdjust}
                                    disabled={!editable || operation === 'VIEW'}
                                    className={'ml-1'}
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.54) !important'
                                    }}
                                    size={"small"}
                                >
                                    <AdjustIcon/>
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box>
                </Grid>

                <Grid container>
                    {dateValidationError === true && (
                        <Grid item sm={6}>
                            <Typography variant={"caption"} color={"secondary"} className={'d-flex align-items-center'}>
                                <WarningIcon/> Existen documentos anteriores
                            </Typography>
                        </Grid>
                    )}

                    {notaCreditoValidationError === true && (
                        <Grid item sm={6}>
                            <Typography variant={"caption"} color={"secondary"} className={'d-flex align-items-center'}>
                                <WarningIcon/> Existen notas de crédito pendientes
                            </Typography>
                        </Grid>
                    )}
                </Grid>

                <List disablePadding style={{
                    maxHeight: 'calc(100vh - 21rem)',
                    overflowY: 'auto',
                    minHeight: '4.5rem'
                }}>
                    {documentos.map((documento, index) => {
                        const partialPayment = isPartialPayment(documento);
                        return (
                            <div key={index}>
                                <ListItem className={operation !== 'VIEW' && partialPayment ? 'background-secondary-light' : ''} disableGutters>
                                    <ListItemText
                                        primary={<>{documento.tipo} {documento.numero} {documento.isPrepago ? <PrepagoIndicator/> : '' }</>}
                                        secondary={`${documento.ingreso} (${documento.dias} días)`}
                                        primaryTypographyProps={{variant: "body2", color: "textSecondary"}}
                                        secondaryTypographyProps={{variant: 'body2', color: "textPrimary"}}
                                    />
                                    <ListItemText
                                        style={{paddingRight: '1.5rem'}}
                                        primary={operation !== 'VIEW' ? `Pend: ${formatPrice(documento.pendiente, true)}` : ''}
                                        secondary={`Tot: ${formatPrice(documento.total, true)}`}
                                        primaryTypographyProps={{variant: "body2", component: 'div', color: "textPrimary", align: 'right'}}
                                        secondaryTypographyProps={{variant: 'body2', component: 'div', color: "textSecondary", align: 'right'}}
                                    />
                                    <TextField
                                        label={'Cancelar'}
                                        style={{width: '9.8rem'}}
                                        value={documento.importe}
                                        onChange={(event) => this.props.onChangeImporte(documento.id, event.target.value)}
                                        onBlur={(event) => {
                                            if (operation === 'VIEW' || !(documento.tipo === 'FAC' || documento.tipo === 'DEB'))
                                                return;

                                            let importe = parseFloat(documento.importe);

                                            if (isNaN(importe) || importe < documento.percepciones)
                                                this.props.setMinImporte(documento, true);
                                            else if (operation === 'EDIT' && importe > documento.max_importe)
                                                this.props.setMaxImporte(documento, true);
                                            else if (importe > documento.pendiente)
                                                this.props.setMaxImporte(documento, true);
                                        }}
                                        InputProps={{
                                            inputComponent: InputPrice,
                                            readOnly: !editable || operation === 'VIEW' || !(documento.tipo === 'FAC' || documento.tipo === 'DEB'),
                                            endAdornment: (
                                                <InputAdornment position="end" style={{margin: '0'}}>
                                                    <Tooltip title={'Máximo'}>

                                                <span>
                                                    <IconButton
                                                        onClick={() => this.props.setMaxImporte(documento, false)}
                                                        size={"small"}
                                                        disabled={!editable || operation === 'VIEW' || !(documento.tipo === 'FAC' || documento.tipo === 'DEB')}
                                                    >
                                                        <FillIMaxIcon fontSize={"small"}/>
                                                    </IconButton>
                                                </span>
                                                    </Tooltip>
                                                </InputAdornment>
                                            ),
                                            startAdornment: (
                                                <InputAdornment position="start" style={{margin: '0'}}>
                                                    <Tooltip title={'Mínimo'}>
                                                <span>
                                                    <IconButton
                                                        onClick={() => this.props.setMinImporte(documento, false)}
                                                        size={"small"}
                                                        disabled={!editable || operation === 'VIEW' || !(documento.tipo === 'FAC' || documento.tipo === 'DEB')}
                                                    >
                                                        <FillMinIcon fontSize={"small"}/>
                                                    </IconButton>
                                                </span>
                                                    </Tooltip>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <PopupState variant="popover" popupId={documento.id}>
                                        {popupState => (
                                            <React.Fragment>
                                                <Tooltip title={'Opciones'}>
                                                    <IconButton size={"small"} {...bindTrigger(popupState)}>
                                                        <MenuIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                                <Menu keepMounted {...bindMenu(popupState)}>
                                                    <MenuItem onClick={() => {
                                                        if (!editable)
                                                            return;
                                                        popupState.close();
                                                        this.props.onRemove(documento.id);
                                                    }}>
                                                        <IconButton size={"small"} disabled={!editable}>
                                                            <DeleteIcon/>
                                                        </IconButton>&nbsp;
                                                        Quitar
                                                    </MenuItem>
                                                    <MenuItem onClick={() => {
                                                        popupState.close();
                                                        this.props.showInfoDocumento(documento.id);
                                                    }}>
                                                        <IconButton size={"small"}>
                                                            <InfoIcon/>
                                                        </IconButton>&nbsp;
                                                        Detalles
                                                    </MenuItem>
                                                </Menu>
                                            </React.Fragment>
                                        )}
                                    </PopupState>
                                </ListItem>
                                {operation !== 'VIEW' && partialPayment && (
                                    <Typography
                                        variant={"caption"}
                                        component={"div"}
                                        align={"center"}
                                        className={'background-secondary mb-3'}
                                    >
                                        Cancelación Parcial
                                    </Typography>
                                )}
                            </div>
                        )
                    })}

                    {documentos.length === 0 && (
                        <Typography variant={"body2"} align={"center"} color={"textSecondary"} className={'pt-4 pb-2'}>
                            Ingrese un documento a cancelar
                        </Typography>
                    )}
                </List>
            </React.Fragment>
        )
    }
}

CobranzaDocumentosList.defaultProps = {
    onChangeImporte: () => {
    },
};

CobranzaDocumentosList.propTypes = {
    documentos: PropTypes.array.isRequired,
    onChangeImporte: PropTypes.func,
    onRemove: PropTypes.func,
    showInfoDocumento: PropTypes.func,
    onAdd: PropTypes.func
};

CobranzaDocumentosList.contextType = MainContext;
CobranzaDocumentosList = withSnackbar(CobranzaDocumentosList);
